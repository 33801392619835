/* CUSTOM STARTS HERE */
$color__talentia-light-text: #fff;
$color__talentia-dark-gray: #3c3c3c;
$color__talentia-medium-gray: #575756;
$color__talentia-light-gray: #dadada;
$color__talentia-orange: #fbbf00;
$color__talentia-yellow: #fcd557;



$a-tags: 'a, a:active, a:hover, a:visited';
$a-tags-hover: 'a:active, a:hover, a:focus';
/* RESPONSIVE */
@mixin respond-to($media) {

    @if $media == phones {
        /* Extra small devices (phones, less than 768px) */
        @media only screen and (max-width: $screen-sm-min - 1) {
            @content;
        }
    }


    @else if $media == tablets {
        /* Small devices (tablets, 768px to 991px) */
        @media only screen and (min-width: $screen-sm-min) and (max-width: $screen-md-min - 1) {
            @content;
        }
    }

    @else if $media == desktops {
        /* Medium devices (desktops, 992px to 1199px) */
        @media only screen and (min-width: $screen-md-min) and (max-width: $screen-lg-min - 1) {
            @content;
        }
    }
}

@mixin optional-at-root($sel) {
    @at-root #{if(not &, $sel, selector-append(&, $sel))} {
        @content;
    }
}

@mixin placeholder {
    @include optional-at-root('::-webkit-input-placeholder') {
        @content;
    }

    @include optional-at-root(':-moz-placeholder') {
        @content;
    }

    @include optional-at-root('::-moz-placeholder') {
        @content;
    }

    @include optional-at-root(':-ms-input-placeholder') {
        @content;
    }
}

/* Mixin vertical center */
@mixin vertical-align($position: relative) {
    position: $position;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

/* HTML */
html, body {
    font-family: 'Open Sans', sans-serif;
    font-size: 11px;
    line-height: 1.5;
    background-color: $color__talentia-dark-gray;
    color: $color__talentia-medium-gray;
}

main {
    margin: 93px 0;
}

h1,h2,h3,h4,h5,h6 {

}

/* FILE UPLOAD */
#form-right-wrap {
    margin-top: 40px;
}

/* MAIN TEXT SECTION */
.main-text {
    text-align: center;
    h1 {
        font-size: 31px;
        color: $color__talentia-light-text;
        margin: 30px 0 4px;
    }

    p {
        font-size: 21px;
        color: $color__talentia-light-text;

        span {
            color: $color__talentia-orange;
        }
    }
}


/* FORM */
.talentia-form-wrap {
    background-color: $color__talentia-orange;
    margin-top: 2px;

    input,textarea {

    }

    .person-name {
        font-size: 31px;
        color: $color__talentia-light-text;
        font-weight: bold;

        @include placeholder {
            color: $color__talentia-light-text;
            opacity:  1;
        }

    }

    .person-text {
        max-width: 100% !important;
        font-size: 12px;
        height: 180px;

        @include placeholder {
          color: #555;
        }
    }

    .form-control {
        background-color: $color__talentia-yellow;
        margin-bottom: 9px;
        border: 0;
        border-radius: 0;
        height: auto;

        &:focus {
            box-shadow: none;
            outline: 0 none;
        }

        &.digit-2 {
            width: 2em;
            letter-spacing: 0.05em;
        }
        &.digit-4 {
            width: 4em;
            letter-spacing: 0.05em;
        }
    }


    .extra-fields .form-control {
        display: inline;
        padding: 3px 4px;
    }

    .extra-fields {

        .extra-left {
            padding-right: 0;
        }
        .extra-right {
            padding-left: 10px;
        }
    }
}

#image-delete {
  background: #000 none repeat scroll 0 0;
  color: #fff;
  left: 13px;
  padding: 4px 8px;
  position: absolute;
  top: 0;
  z-index: 10;
}

a, a:link, a:visited {
    color: $color__talentia-medium-gray;
    text-decoration: none;
    outline : none;
    font-weight: bold;
}

a:hover, a:active {
    color: $color__talentia-dark-gray;
    text-decoration: none;
    outline : none;
}


/* DROPZONE */
#talentia-dropzone {
    width: 100%;
    height: 100%;
    position: relative;
    background-color: $color__talentia-light-gray;
    border-radius: 4px;
    width: 100%;

    .dz-message {
        text-align: center;
        @include vertical-align();

        h3 {
            font-size: 16px;
            margin: 6px 0 0;
        }
    }

    #loader {
        display: none;
        text-align: center;
        @include vertical-align();
    }


}

/* SITEWIDE BOOTSTRAP OVERRIDES*/
.talentia .well {
    border: 0 none;
    margin-bottom: 5px;
}

.btn-talentia {
    background: $color__talentia-orange;
    text-transform: uppercase;
    font-weight: bold;

    &:hover, &:active, &:focus {
        color: $color__talentia-dark-gray;
        background-color: $color__talentia-yellow;
    }
}

/* COLOR SCHEME CLASSES */
.bg-medium-gray {
    background-color: $color__talentia-medium-gray;
}

.bg-light-gray {
    background-color: $color__talentia-light-gray;
}


/* SPACERS */
div[class^="spacer-"], div[class*=" spacer-"] {
    width: 100%; font-size: 0; margin: 0; padding: 0; border: 0; display: block;
}
.spacer-5 { height: 5px; }
.spacer-10 { height: 10px; }
.spacer-15 { height: 15px; }
.spacer-20 { height: 20px; }
.spacer-25 { height: 25px; }
.spacer-30 { height: 30px; }
.spacer-35 { height: 35px; }
.spacer-40 { height: 40px; }
.spacer-45 { height: 45px; }
.spacer-50 { height: 50px; }
.spacer-55 { height: 55px; }
.spacer-60 { height: 60px; }
.spacer-70 { height: 70px; }
.spacer-80 { height: 80px; }
.spacer-90 { height: 90px; }
.spacer-100 { height: 100px; }
.spacer-200 { height: 200px; }

.mb-5 { margin-bottom: 5px;}
.mb-10 { margin-bottom: 10px;}
.mb-15 { margin-bottom: 15px;}
.mb-20 { margin-bottom: 20px;}
.mb-25 { margin-bottom: 25px;}
.mb-30 { margin-bottom: 30px;}
.mb-40 { margin-bottom: 40px;}
.mb-50 { margin-bottom: 50px;}


/* UTILITY */
.ta-c { text-align: center; }

.fl-l {
    float: left;
}

.d-tbl {
    display: table;
}

.d-tbc {
    display: table-cell;
}

.p-r0 {
    padding-right: 0;
}

.text-center-sm {
    @include respond-to(tablets) {
        text-align: center;
    }

    @include respond-to(phones) {
        text-align: center;
    }
}

.right-block {
    display: block;
    margin-left: auto;
    margin-right: 0;
}

.center-block-sm {
    @include respond-to(tablets) {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    @include respond-to(phones) {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

.center-block-xs {
    @include respond-to(phones) {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

#dropzone-profile-image {
    border-radius: 4px;
}

#img-juko {
    max-width: 120px;
    margin-bottom: 1em;
}

.position-relative {
    position: relative;
}

#counter {
    position: absolute;
    bottom: 10px;
    right: 25px;
    font-size: 0.75em;
}
